"use client";

import React, { useState, useEffect } from "react";
import Image from "next/image";
import BookAppointmentButtonPurple from "@/app/components/buttons/BookAppointmentButtonPurple";
import ButtonPurple from "@/app/components/buttons/ButtonPurple";

const images = [
  "/imgs/i8rear.jpg",
  "/imgs/c8shop.jpg",
  "/imgs/redporsche.jpg",
  // Add more image paths as needed
];

export default function HomeBanner() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setNextImageIndex((currentImageIndex + 1) % images.length);
      setTimeout(() => {
        setCurrentImageIndex(nextImageIndex);
      }, 1000); // This should match the fade duration
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [currentImageIndex, nextImageIndex]);

  return (
    <div className="relative w-full h-[500px] overflow-hidden">
      {images.map((img, index) => (
        <div
          key={img}
          className="absolute inset-0 transition-opacity duration-1000 ease-in-out"
          style={{
            opacity: index === currentImageIndex ? 1 : 0,
          }}
        >
          <Image
            src={img}
            alt={`Background image ${index + 1}`}
            fill
            style={{ objectFit: "cover" }}
            quality={100}
            priority={index === 0} // Preload the first image
          />
        </div>
      ))}
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Content */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-white text-center px-4">
        <h4 className="text-lg">Welcome to Masonic Smog & Repair</h4>
        <h1 className="text-4xl md:text-6xl font-bold mt-4">
          Your Reliable Auto Repair Shop in San Francisco, CA
        </h1>
        <div className="mt-8 flex flex-col sm:flex-row gap-4 ">
          <BookAppointmentButtonPurple />
          <ButtonPurple text={"Call Now"} link={"tel:(415)272-0531"} />
        </div>
      </div>
    </div>
  );
}
