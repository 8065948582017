import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/body/HomeBanner.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/carousels/FlexibleCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/nav/MobileNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/nav/NavBarWithCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/templates/FAQtemplate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/templates/ReviewCard.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/templates/ServiceCard.tsx");
