"use client";

import { useState } from "react";
import Link from "next/link";

interface ReviewCardProps {
  source: string;
  author: string;
  body: string;
  rating: number;
  sourceLink: string; // New prop for the source link
}

const StarIcon = ({ filled }: { filled: boolean }) => (
  <svg
    className={`w-5 h-5 ${filled ? "text-red-500" : "text-gray-300"}`}
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
  </svg>
);

export default function ReviewCard({
  source,
  author,
  body,
  rating = 5,
  sourceLink,
}: ReviewCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="w-full max-w-md mx-auto border-2 border-none shadow-sm rounded-md bg-gray-300">
      <div className="p-6 space-y-4">
        <h2 className="text-xl font-bold">-{author}</h2>
        <div className={`relative ${isExpanded ? "" : "h-24 overflow-hidden"}`}>
          <p className=" italic">&quot;{body}&quot;</p>
          {/*{!isExpanded && (*/}
          {/*  <div className="absolute bottom-0 left-0 w-full h-12 bg-gradient-to-t from-white to-transparent"></div>*/}
          {/*)}*/}
        </div>
        {body.length > 150 && (
          <button
            onClick={toggleExpand}
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
          >
            {isExpanded ? "Read less" : "Read more"}
          </button>
        )}
      </div>
      <div className="flex flex-col items-center p-6 pt-0 space-y-2">
        <Link
          href={sourceLink}
          className="text-xl text-[#3C81F6] underline hover:text-blue-800"
        >
          {source}
        </Link>
        <div className="flex">
          {[...Array(5)].map((_, i) => (
            <StarIcon key={i} filled={i < rating} />
          ))}
        </div>
      </div>
    </div>
  );
}
