"use client";

import { useState } from "react";

export interface FAQItem {
  question: string;
  answer: string;
}

interface FAQAccordionProps {
  faqs: FAQItem[];
  title?: string;
}

export default function FAQtemplate(
  { faqs, title = "Frequently Asked Questions" }: FAQAccordionProps = {
    faqs: [],
  },
) {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="max-w-2xl mx-auto my-5 p-4 border-4 rounded-md">
      <h2 className="text-2xl font-bold text-center mb-6">{title}</h2>
      <div className="space-y-4 animate-[slide-down_0.3s_ease-out]">
        {faqs.map((faq, index) => (
          <div key={index} className="border-b pb-4">
            <button
              onClick={() => toggleFAQ(index)}
              className="flex items-center justify-between w-full text-left text-lg font-medium"
            >
              <span>{faq.question}</span>
              <span>{openIndex === index ? "\u2212" : "+"}</span>
            </button>
            {openIndex === index && (
              <div className="mt-2 p-4 bg-gray-100 rounded">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
